import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 3`}</h1>
    <p>{`This week contains new music by Shanti Celeste, a mind-blowing remix by Voiski, a lovely selection by Martyn on the Ilian Tape Podcast Series and some lush soundscapes by Mikron.`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2115870752/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=979704123/transparent=true/" seamless>
  <a href="http://shop.cpurecords.net/album/severance">Severance by Mikron</a>
    </iframe>
    <h1>{`Mikron - Ghost Node`}</h1>
    <p>{`Mikron released one of the best electronic albums of 2019 on Central Processing Unit. The whole EP is worth diving in, but Ghost Node is an absolute beauty on this one.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.discogs.com/Mikron-Severance/release/13037142"
      }}>{`Sold out -> Discogs sharks`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2097810011/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/track=2762102918/transparent=true/" seamless>
  <a href="http://lobstertheremin.com/album/timeframe-ep">
    Timeframe EP by Shedbug
  </a>
    </iframe>
    <h1>{`Shedbug - Timeframe (Voiski Remix)`}</h1>
    <p>{`Hard hitting remix from Voiski! Be sure to also check out the great tracks by Shedbug.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://lobstertheremin.com/album/timeframe-ep"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180px" height="180px" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/743779036&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    <h1>{`ITPS047 MARTYN`}</h1>
    <p>{`Fantastic mix by Martyn on Ilian Tape, this will keep your workday going for sure!`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://nousklaer.bandcamp.com/album/white-heron"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180" height="180" src="https://www.youtube.com/embed/4LBoIUUZqB4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`MJ Cole - Dizzy New Heights (Shanti Celeste Remix)`}</h1>
    <p>{`New project by MJ COLE where he has been working on for the past 20 years (!!!). He will be doing things differently this time, by first releasing remixes of the tracks. The first one is an incredible remix of Shanti Celeste.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.junodownload.com/products/mj-cole-dizzy-new-heights-shanti-celeste/4406654-02/"
      }}>{`Juno`}</a></p>
    <div style={{
      marginBottom: "80px"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      